.sidebar {
  min-height: 100%;
  padding: 0;
  position: absolute;
  width: $sidebar-width-lg;
  z-index: 1;
  @include sidebar-toggle-animation();

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 40px;

    img {
      max-width: 85px;
      width: 100%;
      border-radius: 100%;
      margin: 0 auto 15px;
      border: 3px solid #fff;
    }

    p.name {
      text-align: center;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 500;
    }

    p.designation {
      text-align: center;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 500;
      color: #cacdd7;
    }

    span.online {
      border-radius: 3px;
      background: #58d8a3;
      margin: auto;
      text-align: center;

      &:after {
        content: "Online";
        color: #fff;
        display: block;
        font-size: 10.5px;
        padding: 2px 6px 0;
      }
    }
  }

  .nav {
    display: block;
    padding: 10px 0px 10px 20px;

    .item-category{
      color: grey;
      font-weight: 500;
    }
    .nav-item {
      &:last-child {
        margin-bottom: 30px;
      }

      .collapse {
        z-index: 999;
      }
      .collapsing{
        @include transition-duration(0s);
      }

      a.nav-link {
        color: color(gray-light);
        padding: 1em;
        white-space: nowrap;
        font-size: 13px;
        @extend .d-flex;
        .fa {
          &:first-child {
            margin-right: 0.5em;
          }
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 15px;
        }

        .menu-title {
          display: inline-block;
          @extend %ellipsor;
          .fa {
            margin-left: 0.5em;
          }
        }

        &:hover {
          color: darken(color(gray-light), 15%);
        }
      }

      &.active {
        > .nav-link {
          color: theme-color(primary);
        }
      }
    }

    &.sub-menu {
      padding-left: 2.5em;

      .nav-item {
        &.active {
          .nav-link {
            border-left: 0;
            background: transparent;
          }
        }
      }
    }
  }

  // &.sidebar-fixed {
  //   @media(min-width: 992px) {
  //     position: fixed;
  //   }
  //
  //   .nav {
  //     max-height: calc(100vh - 275px);
  //     overflow: auto;
  //     position: relative;
  //
  //     &.sub-menu {
  //       max-height: none;
  //     }
  //   }
  // }
}
/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;

    &.row-offcanvas-right {
      right: 0;

      .sidebar-offcanvas {
        right: -100%; // 12 columns
      }

      &.active {
        right: 50%; // 6 columns

        .sidebar-offcanvas {
          right: -50%; // 6 columns
        }
      }
    }

    &.row-offcanvas-left {
      left: 0;

      .sidebar-offcanvas {
        left: -100%; // 12 columns
      }

      &.active {
        left: 50%; // 6 columns

        .sidebar-offcanvas {
          left: -50%; // 6 columns
        }
      }
    }

    .sidebar-offcanvas {
      position: absolute;
      top: 0;
      width: 50%; // 6 columns
    }
  }
}
@media screen and (max-width: 350px) {
  //for smaller devices like iphone 5

  .row-offcanvas {
    &.row-offcanvas-right {
      &.active {
        right: 100%;

        .sidebar-offcanvas {
          right: -100%;
        }
      }
    }

    .sidebar-offcanvas {
      width: 100%;
    }
  }
}
