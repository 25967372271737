.icons-list {
  >div {
    background: color(gray-light)est;
    border: 1px solid $white;
    font-weight: 300;
    padding: .5rem;
    text-align: center;

    i {
      display: block;
      font-size: 1.5rem;
      margin: .5rem auto;
    }
  }
}

.icon-grey-big {
  color: color(gray-light);
  font-size: 2.5rem;
}
