body,
html {
	font-family: $popins;
	font-weight: 400;
	font-size: 0.9rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.progress-slim {
	height: 4px;
}

.txt-brand-color {
	color: theme-color(primary);
}

.progress-bar {
	font-weight: 600;
}

input {
	font-family: $popins;
	font-size: 13px;
	min-height: 40px;
	@include border-radius(6px);
	padding: 9px 15px;
}

.card {
	border-color: $brand-border-color;

	&.card-rounded {
		@include border-radius(5px);
	}

	&.card-statistics {
		.highlight-icon {
			font-size: 3.125rem;
		}

		.highlight-icon-small {
			font-size: 2.5rem;
		}

		p {
			margin-bottom: 0;
		}
	}

	.fa-2x {
		font-size: 1.4em;
	}
}

.border-success {
	border: 1px solid theme-color(success);
}

.border-primary {
	border: 1px solid theme-color(primary);
}

.border-warning {
	border: 1px solid theme-color(warning);
}

.card-outline-danger {
	border: 1px solid theme-color(danger);
}

.content-wrapper {
	padding: 2rem 1.5rem;
}

.card-body {
	padding: 1.9rem 1.5rem;
}

.badge {
	padding: 0.50em 0.55em;
	font-weight: 500;
	&.badge-warning,&.badge-success{
		color: $white;
	}
}

.jumbotron {
	background-color: #F2F7F8;
}

form.forms-sample {
	@extend .row;

	.form-group {
		@extend .col-xl-6;
		@extend .col-lg-6;
		@extend .col-md-6;
		@extend .col-sm-6;
		@extend .col-12;
	}
}

.bg-blue {
	background: theme-color(primary);
}

.card {
	border-radius: 0;
	border: 0;

	&.card-statistics {
		.highlight-icon {
			font-size: 3.125rem;
		}

		p {
			margin-bottom: 0;
		}
	}

	&.card-faded {
		background: #b5b0b2;
		border-color: #b5b0b2;
	}

	&.card-circle-progress {
		color: $white;
		text-align: center;
	}
}
// Dashboard Components Starts

.count {
	font-size: 30px;
	@include rotate(0);

	&.count-up {
		color: theme-color(success);
	}
}

.product-chart-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
	height: 92%;

	.products-chart {
		width: auto;
		display: inline-block;
		margin-right: 40px;
	}
}

.error-page {
	h1 {
		font-family: $gudea;
		font-size: 150px;
		font-weight: bold;
		text-align: center;
		color: theme-color(primary);
	}

	h2 {
		font-family: $gudea;
		font-size: 24px;
		text-align: center;
		color: #000;
	}

	p {
		font-family: $gudea;
		font-size: 18px;
		text-align: center;
		color: #4a4949;
	}

	button {}
}

.auth-pages {
	h3 {
		font-family: $gudea;
		font-size: 23px;
		font-weight: bold;
		line-height: 1.3;
		color: #000;
	}

	.form-control {
		height: 48px;
		@include border-radius(3px);
		background-color: #f2f7fa;
		border: none;
	}

	.forgot-pass {
		font-family: $gudea;
		font-size: 13px;
		line-height: 2.31;
		text-align: right;
		color: #1c2c3f;
	}

	button {
		font-family: $gudea;
		font-size: 15px;
		text-align: center;
		color: #ffffff;
		border: none;

		&.enter-btn {
			height: 39px;
			background-image: -webkit-gradient(linear, left top, right top, from(#318be3), to(#04befe));
			background-image: linear-gradient(to right, #318be3, #04befe);
		}
	}

	.Or-login-with {
		font-family: Gudea;
		font-size: 15px;
		line-height: 2;
		text-align: center;
		color: #737475;
	}
	a{
		&.btn{
			height: 39px;
			font-family: Gudea;
		}
		&.btn-facebook{
		  background-color: #3f51b5;
			color: $white;
			border: none;
			line-height: 1.88;
			position: relative;
			&:before{
				font: normal normal normal 14px/1 FontAwesome;
		    font-size: 14px;
				font-size: inherit;
				text-rendering: auto;
				content: "\f09a";
				display: inline;
				position: absolute;
				top: 10px;
				left: 16px;
				font-size: 20px;
			}
		}
		&.btn-google{
		  background-color: #f44336;
			color: $white;
			border: none;
			line-height: 1.88;
			position: relative;
			&:before{
				font: normal normal normal 14px/1 FontAwesome;
		    font-size: 14px;
				font-size: inherit;
				text-rendering: auto;
				content: "\f0d5";
				display: inline;
				position: absolute;
				top: 10px;
				left: 16px;
				font-size: 20px;
			}
		}
		&.btn-create-account{
			background: $white;
  		border: solid 1px #ebebeb;
			color: #1c2c3f;
		}
	}
}
