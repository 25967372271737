
.navbar {
  &.fixed-top {
    + .container-fluid {
      padding-top: 3.5rem;
    }
  }
}


.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      box-shadow: 7px -18px 30px rgba(0, 0, 0, 0.07);
      .user-info{
        display:none;
      }
      .nav {
        padding: 10px 0px 10px 0px;
        max-height: calc( 100vh - 70px);
        overflow: visible;
        .nav-item {
          position: relative;
          .nav-link {
            text-align: center;
            display: block;
            img{
              margin-right: auto;
              margin-left: auto;
            }
            .menu-title {
              display: none;
              &.show {
                i{
                  display: none;
                }
                position: absolute;
                left: $sidebar-width-icon;
                top: 0;
                background:$white;
                display: inline-block;
                text-align: left;
                width: $sidebar-width-lg;
                max-width: none;
                overflow: visible;
                padding: 1rem;
                z-index: 1;
                font-weight: 600;
                font-size: 0.81rem;
                color: darken(color(gray-light), 15%);
                box-shadow: 9px 3px 10px rgba(0, 0, 0, 0.08);
              }
            }
          }
          .collapse{
            &.show{
              width: $sidebar-width-lg;
              padding: 0 20px;
              background: $white;
              z-index: 1;
              position: absolute;
              left: $sidebar-width-icon;
              box-shadow: 9px 3px 10px rgba(0, 0, 0, 0.08);
              color: darken(color(gray-light), 15%)
            }
          }
        }
        &.sub-menu {
          padding-left: 0;
          padding-top: 0;
          .nav-item {
            .nav-link {
              padding: 8px 20px 8px 15px;
              text-align: left;
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .content-wrapper,.footer {
      margin-left: $sidebar-width-icon;
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}
