.navbar {
  .search {
    width: 200px;
    border-radius: 100px;
    font-size: 14px;
    @include transition-duration(0.4s);
    @include transition-timing-function(ease);
    border: none;
    min-height: 0;

    &:focus {
      width: 240px;
    }
  }

  .navbar-brand-wrapper {
    @include sidebar-toggle-animation();
    width: $sidebar-width-lg;

    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 2rem;
      margin-right: 0;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        max-width: 100%;
        width: 60%;
        margin: auto;
      }
    }

    .brand-logo-mini {
      display: none;
    }
  }

  .navbar-menu-wrapper {
    @include sidebar-toggle-animation();
    padding: 1px 15px;
    width: calc(100% - #{$sidebar-width-lg});

    .navbar-toggler {
      border: none;
      font-family: $popins;
      font-size: 16px;

      span {
        font-size: 18px;
        margin-right: 10px;
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;

      .nav-item {
        .nav-link {
          font-size: 0.875rem;
          margin-left: 5px;
          margin-right: 15px;

          .fa {
            font-size: 1.3rem;
          }
        }
      }
    }

    @media (max-width: 991px) {
      width: auto !important;
    }
  }
  //navbar color variations Starts
  &.navbar-default {
    @extend .bg-primary-gradient;
  }

  ul.navbar-nav {
    @extend .flex-row;

    li {
      margin-right: 20px;

      a.profile-pic {
        img {
          width: 35px;
        }
      }
    }

    .nav-link {
      color: lighten(theme-color(primary), 50%);

      i {
        font-size: 19px;
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;

    .navbar-brand-wrapper {
      width: 75px;

      .brand-logo {
        display: none;
      }

      .brand-logo-mini {
        display: inline-block;
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}
