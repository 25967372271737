@mixin fontFace($family,$src,$style: normal,$weight: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$src}.eot'); // IE9 compat
		src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
			url('#{$src}.woff') format('woff'), // standards
			url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
			url('#{$src}.svg##{$family}') format('svg'); // legacy iOS
		font-style: $style;
		font-weight: $weight;
	}
}

@import url('https://fonts.googleapis.com/css?family=Gudea:400,400i,700|Poppins:300,400,500,600,700|Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
