.doc-ul {
  li {
    font-size: 14px;
  }
}


.bold{
  font-weight: 400;
}
.bolder{
  font-weight: 500;
}
.boldest{
  font-weight: 600;
}
