#sales-chart-legend{
  margin-top: 44px;
  margin-bottom: 0;
  ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    @include display-flex;
    @include justify-content(center);
    li{
      display: inline-block;
      margin-top: 0px;
      margin-bottom: 0;
      padding: 0.4rem 0.6rem;
      @extend %ellipsor;
      span{
        width: 100% !important;
        height: 3px !important;
        margin-right: auto;
        display: block;
        border-radius: 14px;
        margin-bottom: 4px;
        margin-left: auto;
      }
    }
  }
}
