.input-group-addon {
  width: 40px;
}


.input-group {
  .form-control {
    min-height:40px;
    height:100%;
  }
  .input-group-addon{
    background-color:#fff;
    color:color(gray-light);
  }
  &:focus{
    color: grey;
  }
}







%b-l-blue-properties{
  border:1px solid rgba(33, 170, 237, 0.25);
  color:color(gray-light);
  background-color:#fff;
}

input.b-l-blue,table.b-l-blue{
  @extend %b-l-blue-properties;
}

.b-l-blue{
  &.input-group{
    .form-control{
      @extend %b-l-blue-properties;
    }
    .input-group-addon{
      @extend %b-l-blue-properties;
    }
  }
  &.form-control{
    @extend %b-l-blue-properties;
  }
}

input[type="search"],input,.form-control{
  @include placeholder {
    color: color(gray-light);
    font-weight:500;
    font-size: 12px;
  }
  color: color(gray-light);
  font-weight:500;
  font-size: 12px;
}






.p_input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}
.p_input::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}
.p_input:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}
.p_input:-moz-placeholder { /* Firefox 18- */
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}



.form-check,.form-radio{
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  label{
    display: block;
    padding-left: 30px;
    font-size: 13px;
    line-height: 1.42857143;
    input{
      position: absolute;
      margin-left: -20px;
      margin-top: 4px\9;
      top: 0;
      left: 0;
      margin-left: 0 !important;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      margin-top: 0;
      &:checked{
        +.input-helper{
          &:before{
            background-color: theme-color(primary);
            border: none;
          }
          &:after{
            opacity: 1;
            line-height: 1.5;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
    .input-helper{
      &:before{
        position: absolute;
        content: "";
        top: 0;
        width: 17px;
        height: 17px;
        border-radius: 2px;
        left: 0;
        border: 2px solid color(gray-light);
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
      }
      &:after{
        line-height: 1.5;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        font-family:FontAwesome;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        content: "\f00c";
        position: absolute;
        font-size: 12px;
        left: 1px;
        top: 0px;
        color: $white;
        font-weight: 300;
      }
    }
  }
  &.disabled{
    opacity: 0.6;
    label{
      cursor: not-allowed;
    }
  }
}


.form-radio{
  label{
    input{
      &:checked{
        +.input-helper{
          &:before{
            background-color: $white;
            border: 2px solid theme-color(primary);
          }
        }
      }
    }
    .input-helper{
      &:before{
        top: -1px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
      }
      &:after{
        content: "";
        width: 9px;
        height: 9px;
        background: theme-color(primary);
        border-radius: 50%;
        top: 4px;
        left: 5px;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
      }
    }
  }
}
