.bg-white {
  @include bg(white);
}
.bg-primary-gradient {
  @include bg-gradient($brand-gradient-left,$brand-gradient-right);
}

.bg-success-gadient{
	@include bg-gradient(#2bb415,#5ad946);
}

.bg-info-gadient{
	@include bg-gradient(#1991eb,#2ea1f8);
}

.bg-warning-gadient{
	@include bg-gradient(theme-color(warning) , #FFB656);
}

.bg-danger-gadient{
	@include bg-gradient(theme-color(danger) , #f85258);
}
